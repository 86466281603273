import React, { useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby'
import Menu from '../Menu'
import MenuMobile from '../MenuMobile'
import Logo from "../../assets/images/novesadybrno-logo.svg";

const header = ({ isContactPage }) => {
    useEffect(() => {
        // Mount
        animatedFixedHeader();

       // Unmount
        return () => {
        }
    }, [])

    const data = useStaticQuery(graphql`
  query HeaderQuery {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        subtitle {
          value
        }
        title {
          value
        }
        domain {
          value
        }
      }
    }
    kontentItemMenu(system: {codename: {eq: "navigation_menu"}}) {
    elements {
      menu_items {
        value { 
          ... on kontent_item_menu_item {
            system {
              id
            }
            id
            elements {
              title {
                value
              }
              content {
                value {
                  ... on kontent_item_contact_page {
                    elements {
                      slug {
                        value
                      }
                    }
                  }
                  ... on kontent_item_simple_page {
                    elements {
                      slug {
                        value
                      }
                    }
                  }
                }
              }   
              submenu_content {
                value {
                  system {
                    name
                    codename
                    id
                    type
                  }
                  ... on kontent_item_simple_page {
                    id
                    elements {
                      title {
                        value
                      }
                      slug {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
    )

    const menu = data.kontentItemMenu
    const domain = data.kontentItemSiteMetadata.elements.domain.value
    const isContact = isContactPage

    return (
        <div>
            <div className="mobile-nav-wrapper">
                <div className="mobile-menu-inner">
                    <MenuMobile data={menu} />
                </div>
            </div>
            <div className="mobile-menu-overlay" onClick={toggleMobileMenu}></div>

            <header className="site-header fixed-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-wrap">
                                <div className="site-header-logo">
                                    <Link
                                        to="/"
                                        className="site-header-logo__link"
                                        data-tracking-label="Header_Logo"
                                    >
                                        <img src={Logo} alt="Logo Nové sady Brno" width="228" height="58" className="site-header-logo__image" />
                                        <span className="site-header-logo__text">{domain}</span>
                                    </Link>
                                </div>
                                <div className="header-nav">
                                    <Menu data={menu} />
                                </div>
                                <div className="header-widgets">
                                    <ul className="right-menu">
                                        {isContact == null ? (
                                            <li className="menu-item free-quote">
                                                <Link to="/kontakt" className="button button--ghost" data-tracking-label="Header_CTA_Kontakt">Pošlete nám dotaz</Link>
                                            </li>
                                        ) : ""}
                                        <li className="menu-item menu-mobile-nav">
                                            <Link to="#" className="menu-bar" id="menu-show-mobile-nav" data-tracking-label="Header_Hamburger" onClick={toggleMobileMenu}>
                                                <span className="hamburger"></span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )

    // Header fixed vs. top
    function animatedFixedHeader() {
        var site = document.documentElement,
            header = document.querySelector('.site-header'),
            headerFixed = document.querySelector('.fixed-header'),
            isScrolled = false,
            animateHeaderOn = 50;

        function initializeFixedHeader() {
            window.addEventListener('scroll', function (event) {
                if (!isScrolled) {
                    isScrolled = true;
                    setTimeout(pageScroll, 100);
                }
            }, false);
            window.addEventListener('load', function (event) {
                if (!isScrolled) {
                    isScrolled = true;
                    setTimeout(pageScroll, 100);
                }
            }, false);
        }

        function pageScroll() {
            var sy = scrollVertically();
            if (sy >= animateHeaderOn) {
                header.classList.add("is-fixed");
            } else {
                header.classList.remove("is-fixed");
            }
            isScrolled = false;
        }

        function scrollVertically() {
            return window.pageYOffset || site.scrollTop;
        }
        if (headerFixed.classList.contains('fixed-header')) {
            initializeFixedHeader();
        }
    };

    // Mobile Nav
    function toggleMobileMenu() {

        var openMobileNav = document.querySelector('.menu-bar'),
            mobileNav = document.querySelector('.mobile-nav-wrapper'),
            overlay = document.querySelector('.mobile-menu-overlay'),
            mobileNavOpened = document.querySelector('.mobile-nav-wrapper.is-open'),
            overlayOpened = document.querySelector('.mobile-menu-overlay.is-open')

        // Open Mobile Nav
        if (!mobileNavOpened && !overlayOpened) {
            openMobileNav.classList.add("active");
            mobileNav.classList.add("is-open");
            overlay.classList.add("is-open");
        }

        // Close Mobile Nav
        if (overlayOpened) {
            openMobileNav.classList.remove("active");
            mobileNav.classList.remove("is-open");
            overlay.classList.remove("is-open");
        }
    }
}

export default header
