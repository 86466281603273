import React from 'react'
import { Link } from 'gatsby'
import { format } from 'react-string-format'

class Menu extends React.Component {
    render() {
        const menu = this.props.data

        const menuBlock = (
            <ul className="main-menu">
                {menu.elements.menu_items.value.map(item => (
                    <li className="main-menu__list-item menu-item-has-children" key={item.id}>
                        {item.elements.content.value.length > 0 ? (
                            <Link
                                to={`${'/'}${item.elements.content.value[0].elements.slug.value}`}
                                className="main-menu__list-item-link"
                                activeClassName="main-menu__list-item-link--active"
                                data-tracking-label={format('MainMenu_{0}', item.elements.title.value)}
                            >
                                {item.elements.title.value}
                            </Link>
                        ) : ("")}
                    </li>
                ))}
            </ul>
        )

        return <nav className="menu">{menuBlock}</nav>
    }
}

export default Menu
